import { commonDefaultRoute } from '@router/defaultroute';

export const guiRoutes: { [path: string]: Route } = {
	'/talent/:talentId/security': {
		...commonDefaultRoute,
		as: 'security',
	},
	'/talent/:talentId/:settingsgroup': {
		...commonDefaultRoute,
		as: 'talent',
		isLegacyTemplate: true,
	},
	'/talent/:talentId': {
		...commonDefaultRoute,
		as: 'talent',
		isLegacyTemplate: true,
	},
	'/avatarverification': {
		...commonDefaultRoute,
		as: 'avatarverification',
	},
	'/webcamsessions': {
		...commonDefaultRoute,
		as: 'webcamsessions',
		isLegacyTemplate: true,
	},
	'/verification': {
		...commonDefaultRoute,
		as: 'verification',
		isLegacyTemplate: true,
	},
	'/schedule': {
		...commonDefaultRoute,
		as: 'schedule',
		isLegacyTemplate: true,
	},
	'/supporttickets': {
		...commonDefaultRoute,
		as: 'supporttickets',
		isLegacyTemplate: true,
	},
	'/assetmanager': {
		...commonDefaultRoute,
		as: 'assetmanager',
	},
	'/': {
		...commonDefaultRoute,
	},
};
